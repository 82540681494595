<script>
import { promiseHelper } from '@/helper/promise-helper'
import { VTooltip } from 'vuetify/lib/components'

export default {
  name: 'v-tooltip',
  extends: VTooltip,
  watch: {
    isContentActive (val) {
      if (val) {
        promiseHelper.delay(3000).then(() => {
          this.callDeactivate()
        })
      }
    }
  }
}
</script>

<style>

</style>